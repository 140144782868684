<script setup>
import FocusBar from '../layout/header/FocusBar.vue'
import StringField from '@/components/ui/Calculator/StringField.vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'

const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const { user_name } = EntityComputedFields.useEntityComputedFields({
  refId: refId,
  store: storeName,
  type: type
})
</script>

<template>
  <FocusBar>
    <template #middle>
      <div class="flex justify-center items-center w-full">
        <div class="flex justify-center items-center w-fit gap-2 mx-4">
          <StringField v-model="user_name" placeholder="No user name" />
        </div>
      </div>
    </template>
  </FocusBar>
</template>

<style scoped lang="scss"></style>
